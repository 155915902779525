import { Container, Row } from "react-bootstrap";

export default function EgitimDanismaniDestegi() {
  return (
    <section>
      <Container>
        <Row>
          <h2 className="text-center mb-3">
            Şirket Hizmet İçi Eğitimler ve Profesyonel Koçluk Desteği
          </h2>
          <img
            src="./images/egitimdanismanidestegi.png"
            alt="img-hero"
            className="img-fluid w-75 mx-auto mb-3"
          />
          <p className="w-75 mx-auto">
            Eğitim danışmanı, öğrencilerin yaşam becerileri, deneyim ve
            tecrübelerini ele alarak potansiyel ve yetenekleri doğrultusunda
            kişinin istek, beklenti, potansiyel ve yeteneklerine göre belirlenen
            hedefe ulaşmaları sürecinde eğitim ve öğretimle ilgili atacağı her
            adımda akademik anlamda bilgi danışmanlığı sağlayarak hedeflerine
            daha emin ve sağlıklı ilerlemesini sağlar. Danışmanlar yönlendirme
            yaptığı konuyla ilgili tam bilgiye sahip ve alanında uzman
            kişilerdir.
          </p>
        </Row>
      </Container>
    </section>
  );
}
