import { Container, Row, Col } from "react-bootstrap";

export default function About() {
  return (
    <section>
      <Container>
        <Row className="d-flex align-items-center mx-auto">
          <Col sm={6} md={6} lg={6} xl={6}>
            <figure>
              <img
                src="./images/galeri/image-26.jpg"
                alt="img-hero"
                fluid="true"
                className="img-fluid"
              />
            </figure>
          </Col>
          <Col sm={6} md={6} lg={6} xl={6}>
            <h3>Mevzu Yaşam Akademi</h3>
            <p>
              Kişisel Gelişim Uzmanı, Profesyonel Koç ve Danışman Gamze Nur
              Akdemir öncülüğünde sizlere yaşamın her alanındaki konularla
              ilgili yardımcı olmayı hedefliyoruz. Koçluk, danışmanlık, öğrenci
              özel ders desteği, sağlıklı ve dengeli, profesyonel yaşam
              konusunda aklınızdaki tüm sorularınıza ışık tutmanızı sağlıyor,
              eğitimlerimizle ve seanslarımızla sizleri destekliyoruz. Eğitim
              koçluğu alanımızda bireysel öğrenci analizi, bireysel program ve
              takibi, bireysel verimli ve doğru çalışma metotları, veli geri
              bildirimi, algılama çalışmaları, sözel ve sayısal mantık, hızlı
              okuma ve hafıza teknikleri, sınav kaygısı kontrolü, zaman
              yönetimi, dikkat dağınıklığı ve odaklanama sorunu desteği ile
              öğrencilerin hedeflerine daha sağlıklı ulaşmalarını sağlıyoruz.
              Eğitim koçluğu danışanlarımızın hedef belirleme ve hedefe ulaşma
              sürecinde sağlıklı karar alma, başarı takip çizelgeleri ile veli
              danışmanlığı sistemimiz ile çalışmalarını ve hedeflerini kontrollü
              destekliyor hedefe ulaşma süreçlerinde ailelerimize de kolaylık
              sağlıyoruz. Profesyonel hayatınızda ise iş ve şirket
              ilişkilerinizde, oryantasyon, adaptasyon, motivasyon gibi
              alanlarda hizmet içi eğitimler sağlıyor profesyonel ve kariyer
              hayatı tercih sürecinizde kariyer koçluğu desteği sağlıyoruz.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
