import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Blog() {
  return (
    <>
      <h2 className="text-center display-2 text-primary mb-3">Blogs</h2>

      <section id="post-6">
        <Container>
          <Row className="mx-auto mb-5 w-75">
            <figure>
              <img
                src="./images/blog/yenidonem.jpg"
                alt="img-hero"
                className="img-fluid"
              ></img>
            </figure>
            <Col className="d-flex align-items-center mb-3">
              <img
                src="./images/blogGamze.png"
                alt="img-hero"
                className="img-fluid rounded-circle"
                width={"35px"}
              />
              <span>&nbsp;Gamze Nur Akdemir</span>
            </Col>
            <span className="text-secondary">06.05.2024</span>
            <h4 className="mb-3">
              <Link
                to="/blog/yeni-dönem-öğrenci-etkili-hazırlık-süreci"
                style={{ textDecoration: "none" }}
              >
                Yeni Döneme Başlarken Öğrenciler İçin Etkili Hazırlık Süreci
              </Link>
            </h4>
            <p>
              Yeni bir döneme başlarken öğrenciler için etkili bir hazırlık
              süreci şu adımları içerebilir:Ders Programını İnceleme: Ders
              programını önceden gözden geçirerek hangi derslerin, ne zaman
              olduğunu belirlemek önemlidir. Bu, öğrencinin haftalık veya aylık
              bir plan yapmasına yardımcı olabilir...
            </p>
          </Row>
        </Container>
      </section>

      <section id="post-7">
        <Container>
          <Row className="mx-auto mb-5 w-75">
            <figure>
              <img
                src="../images/blog/egitimhayatı.jpg"
                alt="img-hero"
                className="img-fluid"
              ></img>
            </figure>
            <Col className="d-flex align-items-center mb-3">
              <img
                src="./images/blogGamze.png"
                alt="img-hero"
                className="img-fluid rounded-circle"
                width={"35px"}
              />
              <span>&nbsp;Gamze Nur Akdemir</span>
            </Col>
            <span className="text-secondary">05.05.2024</span>
            <h4 className="mb-3">
              <Link
                to="/blog/eğitim-hayatında-özgüven"
                style={{ textDecoration: "none" }}
              >
                Eğitim Hayatında Özgüven
              </Link>
            </h4>
            <p>
              Özgüven, bir bireyin kendine güvenme, kendi yeteneklerine inanma
              ve başarıya olan inancıyla ilgili bir kavramdır. Eğitim sürecinde
              özgüven, öğrencilerin akademik ve sosyal gelişimlerinde önemli bir
              role sahiptir...
            </p>
          </Row>
        </Container>
      </section>

      <section id="post-6">
        <Container>
          <Row className="mx-auto mb-5 w-75">
            <figure>
              <img
                src="./images/blog/kaygıcocuk.jpg"
                alt="img-hero"
                className="img-fluid"
              ></img>
            </figure>
            <Col className="d-flex align-items-center mb-3">
              <img
                src="./images/blogGamze.png"
                alt="img-hero"
                className="img-fluid rounded-circle"
                width={"35px"}
              />
              <span>&nbsp;Gamze Nur Akdemir</span>
            </Col>
            <span className="text-secondary">04.05.2024</span>
            <h4 className="mb-3">
              <Link
                to="/blog/çocukların-öğrenme-stilini-keşfedin"
                style={{ textDecoration: "none" }}
              >
                Çocuklarımızın Algılama Biçimini ve Öğrenme Stilini Keşfederek
                Başarı Sağlıyoruz
              </Link>
            </h4>
            <p>
              Çocuğunuz hayatı nasıl algılıyor? Derslerini, anne babasını,
              sosyal hayatını, yaşadığı olaylara durumlara nasıl anlam yüklüyor?
              Bilmek ister misiniz? Neden ısrarla bir derste başarısız olmakta
              kararlı? Neden sayısalı kotuyken sözel dersleri daha iyi? Peki
              neden başka bir çocuk her iki alanda da başarı sağlayabiliyor?
            </p>
          </Row>
        </Container>
      </section>

      <section id="post-5">
        <Container>
          <Row className="mx-auto mb-5 w-75">
            <figure>
              <img
                src="../images/blog/sınav.jpg"
                alt="img-hero"
                className="img-fluid"
              ></img>
            </figure>
            <Col className="d-flex align-items-center mb-3">
              <img
                src="./images/blogGamze.png"
                alt="img-hero"
                className="img-fluid rounded-circle"
                width={"35px"}
              />
              <span>&nbsp;Gamze Nur Akdemir</span>
            </Col>
            <span className="text-secondary">03.05.2024</span>
            <h4 className="mb-3">
              <Link
                to="/blog/bilsem-sınavını-kazanmanın-avantajları"
                style={{ textDecoration: "none" }}
              >
                BİLSEM Sınavını Kazanmanın Avantajları
              </Link>
            </h4>
            <p>
              Öğrenciler, zenginleştirilmiş eğitim programlarına katılma fırsatı
              bulurlar. Bu programlar genellikle normal müfredattan daha ileri
              seviyede konuları içerir ve öğrencilere daha derinlemesine bir
              öğrenme deneyimi sunar...
            </p>
          </Row>
        </Container>
      </section>

      <section id="post-1">
        <Container>
          <Row className="mx-auto mb-5 w-75">
            <figure>
              <img
                src="./images/profesyonelyasamkocudestegi.png"
                alt="img-hero"
                className="img-fluid"
              ></img>
            </figure>
            <Col className="d-flex align-items-center mb-3">
              <img
                src="./images/blogGamze.png"
                alt="img-hero"
                className="img-fluid rounded-circle"
                width={"35px"}
              />
              <span>&nbsp;Gamze Nur Akdemir</span>
            </Col>
            <span className="text-secondary">25.07.2023</span>
            <h4 className="mb-3">
              <Link
                to="/blog/egitim-kocu-tanımlari-ve-görevleri"
                style={{ textDecoration: "none" }}
              >
                Eğitim ve Öğrenci Koçu Tanımı Görevleri ve Olması Gereken
                Özellikler Nelerdir ?
              </Link>
            </h4>
            <p>
              Eğitim ve Öğrenci Koçu kişilerin hedef belirleme ve hedefe ulaşma
              sürecinde kişinin çalışma içerisinde doğru yöntem belirler,
              destekler motive eder ve başarı elde etme becerilerinin
              artırılmasına yönelik çalışmalar yürütür. Kişinin, kendi
              potansiyel ve yeteneklerine yönelik sağlıklı hedefler belirler ve
              hangi kaynaklara ihtiyacı olduğu analiz eder, sahip oldukları
              kaynakları ise daha iyi kullanabilmesi için kişiye yönelik yol
              haritası belirler.
            </p>
          </Row>
        </Container>
      </section>

      <section id="post-2">
        <Container>
          <Row className="w-75 mx-auto mb-5">
            <figure>
              <img
                src="./images/decide.png"
                alt="img-hero"
                className="img-fluid"
              ></img>
            </figure>
            <Col className="d-flex align-items-center mb-3">
              <img
                src="./images/blogGamze.png"
                alt="img-hero"
                className="img-fluid rounded-circle"
                width={"35px"}
              />
              <span>&nbsp;Gamze Nur Akdemir</span>
            </Col>
            <span className="text-secondary">24.07.2023</span>
            <Link
              to="/blog/basarili-bir-karar-verici-misin"
              style={{ textDecoration: "none" }}
            >
              <h4 className="mb-3">Başarılı Bir Karar Verici Misin?</h4>
            </Link>
            <p>
              Yaşantımız boyunca önemli önemsiz, büyük küçük, değerli değersiz
              bir çok konuda karar vermek zorundayız. Peki karar vermek nedir ve
              karar verirken neyi dikkate almalıyız? Acaba bizler sadece doğru
              karar verdiğimizde mi bir karar vermiş oluruz? Peki ya vermiş
              olduğumuz kararlar yanlışsa? Sonuca nasıl katlanıyoruz?
            </p>
          </Row>
        </Container>
      </section>

      <section id="post-3">
        <Container>
          <Row className="w-75 mx-auto mb-5">
            <figure>
              <img
                src="./images/difficultDecide.png"
                alt="img-hero"
                className="img-fluid"
              ></img>
            </figure>
            <Col className="d-flex align-items-center mb-3">
              <img
                src="./images/blogGamze.png"
                alt="img-hero"
                className="img-fluid rounded-circle"
                width={"35px"}
              />
              <span>&nbsp;Gamze Nur Akdemir</span>
            </Col>
            <span className="text-secondary">23.07.2023</span>
            <Link
              to="/blog/karar-verirken-zorlanmak"
              style={{ textDecoration: "none" }}
            >
              <h4 className="mb-3">Karar Verirken Zorlanmak</h4>
            </Link>
            <p>
              Karar verirken zorlanıyor ve bu kararsızlık hali sizi olumsuz
              etkiliyorsa altında bir takım içsel problemler yatıyor olabilir.
              Örneğin özgüven problemi yaşıyorsanız kararınızın sürekli
              sorgulanmasından ve desteklenmemesinden endişe duyarsınız.
              Çocukluk çağında yaşanan travmalar akran zorbalığı istismar gibi
              durumlar özgüven düşüklüğüne sebep olabilir.
            </p>
          </Row>
        </Container>
      </section>

      <section id="post-4">
        <Container>
          <Row className="w-75 mx-auto">
            <figure>
              <img
                src="./images/socialProblem.png"
                alt="img-hero"
                className="img-fluid"
              ></img>
            </figure>
            <Col className="d-flex align-items-center mb-3">
              <img
                src="./images/blogGamze.png"
                alt="img-hero"
                className="img-fluid rounded-circle"
                width={"35px"}
              />
              <span>&nbsp;Gamze Nur Akdemir</span>
            </Col>
            <span className="text-secondary">23.07.2023</span>
            <h4 className="mb-3">
              <Link
                to="/blog/sosyal-etkilesimlerdeki-korku-endise"
                style={{ textDecoration: "none" }}
              >
                Bireyler Sosyal Fobi: Sosyal Etkileşimlerdeki Korku ve Endişe
              </Link>
            </h4>
            <p>
              Sosyal fobi, sosyal etkileşimlerde veya toplum içinde olma
              durumlarında yoğun bir korku veya endişe hissiyle karakterize
              edilen bir anksiyete bozukluğudur. Sosyal fobi, kişinin diğer
              insanların önünde konuşma yapma, toplum önünde performans
              sergileme, başkalarıyla sohbet etme, toplu etkinliklere katılma
              veya hatta basit bir konuşmada bulunma gibi durumlarda aşırı stres
              yaşamasına neden olabilir.
            </p>
          </Row>
        </Container>
      </section>
    </>
  );
}
