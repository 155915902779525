import { Container, Row } from "react-bootstrap";

export default function KariyerKoclugu() {
  return (
    <section>
      <Container>
        <Row>
          <h2 className="text-center mb-3">
            Kariyer Koçluğu ve Danışmanlık Desteği
          </h2>
          <img
            src="./images/kariyerkocudestegi.png"
            alt="img-hero"
            className="img-fluid w-75 mx-auto mb-3"
          />
          <p className="w-75 mx-auto">
            Kariyer koçu, profesyonel hayata geçiş yapan bireylerin kariyer
            basamaklarına adım atarken kendi becerileri, profesyonel alanları,
            deneyim ve tecrübe kazanımları doğrultusunda elde etmek istediği
            mesleğe ve profesyonel hedeflerine ulaşmasına destek olur. Hedefe
            ulaşma aşamasında çalışana veya çalışan adayına stratejik yöntemler
            sunarak kişinin profesyonel hayatta hedeflerine ulaşmasına yardımcı
            olur. Kariyer hayatında kişiye deneyim, potansiyel, yetenek ve
            beklentilerini dikkate alarak bir yol haritası belirler ve kariyer
            gelişimine destek olur.
          </p>
        </Row>
      </Container>
    </section>
  );
}
