import { Container, Row } from "react-bootstrap";
export default function HizliOkumaAnlama() {
  return (
    <section>
      <Container>
        <Row>
          <h2 className="text-center mb-3">
            Hızlı Okuma ve Etkili Anlama Eğitimi
          </h2>
          <img
            src="./images/hizliokuma.png"
            alt="img-hero"
            className="img-fluid w-75 mx-auto mb-3"
          />
          <p className="w-75 mx-auto">
            Gün içerisinde kendimizi sürekli bir şeyler araştırırken ve okurken
            buluruz. Çoğu zaman fark ederiz ki araştırmamız ve öğrenmemiz
            gereken bilgi yoğunluğu kadar zamanımız yeterli değil. Hızlı hızlı
            acele ederek yani zamanla yarışarak okumaya ve araştırmaya
            çalışırken de gözden çok önemli ayrıntıları çok önemli konuları
            kaçırdığımızı hatta odaklanma sorunu ve dikkat dağınıklığı sebebiyle
            okumalarımızda çokça hatalar yaptığımızı fark ederiz. Özellikle
            öğrenciler paragraf sorularında ve uzun sorularda okuma ve anlama
            aşamasında oldukça zaman kaybetmekte her başa dönüş ve tekrar ediş
            ise kişiyi oyalamakta ve zaman yönetimi sorununu ortaya
            çıkarmaktadır. Elbette bu da yanlış cevapları ve başarısızlığı
            beraberinde getirmektedir.
          </p>
          <p className="w-75 mx-auto">
            Hızlı okuma ve etkili anlama uzmanlarımızın eğitimleriyle kısa
            zamanda algınızı güçlendirecek, hafızanızı doğru kullanma
            stratejilerini öğrenecek, göz koordinasyonunuzu sağlayacak, beyin ve
            metin egzersizleri yaparak kısa zamanda doğru cevaba
            ulaşabildiğinizi göreceksiniz. Tüm bunların yanında zamanınızı
            verimli kullanacak az zamanda çok bilgiye ve doğru bilgiye ulaşma
            başarınızın mutluluğunu yaşayacaksınız.
          </p>
        </Row>
      </Container>
    </section>
  );
}
