import { Container, Row, Col } from "react-bootstrap";

export default function PostSeven() {
  return (
    <section>
      <Container>
        <Row className="mx-auto">
          <h3 className="mb-3 w-75 mx-auto">Eğitim Hayatında Özgüven</h3>
        </Row>
        <Row className="w-75 mx-auto">
          <Col className="d-flex align-items-center mb-3">
            <img
              src="../images/blogGamze.png"
              alt="img-hero"
              className="img-fluid rounded-circle"
              width={"35px"}
            />
            <span>&nbsp;Gamze Nur Akdemir</span>
          </Col>
          <img
            src="../images/blog/egitimhayatı.jpg"
            alt="img-hero"
            className="img-fluid mx-auto mb-3"
          />

          <p>
            Özgüven, bir bireyin kendine güvenme, kendi yeteneklerine inanma ve
            başarıya olan inancıyla ilgili bir kavramdır. Eğitim sürecinde
            özgüven, öğrencilerin akademik ve sosyal gelişimlerinde önemli bir
            role sahiptir.
          </p>
          <p>
            Eğitimde özgüven, öğrencilerin başarılarını etkileyen temel bir
            faktördür. Bir öğrencinin kendine olan güveni, onun motive olmasını,
            zorluklarla başa çıkmasını ve hedeflerine odaklanmasını sağlar.
            Kendine güvenen bir öğrenci, başarısızlıkla karşılaştığında pes
            etmek yerine daha fazla çaba sarf eder ve sorunları çözmek için daha
            yaratıcı yaklaşımlar geliştirir. Bu nedenle, özgüvenin eğitim
            sürecindeki rolü büyüktür.
          </p>
          <p>
            Özgüven, çocukluk döneminden itibaren geliştirilmeye başlanabilir.
            Öğrencilerin başarılı olmalarını sağlamak için öğretmenlerin ve
            ebeveynlerin rolü büyüktür. Olumlu geri bildirimler ve teşvikler,
            öğrencilerin kendilerine olan güvenlerini artırır. Başarılarını
            kutlamak ve onları desteklemek, öğrencilerin özgüvenlerini
            geliştirmede önemli adımlardır. Ayrıca, öğrencilere sorumluluk
            vermek ve karar alma süreçlerine katılmalarını teşvik etmek de
            özgüvenlerini artırabilir. Kendi hatalarını fark etmelerine ve
            bunlardan ders çıkarmalarına olanak tanımak, öğrencilerin
            özgüvenlerini geliştirmeye yardımcı olur. Ayrıca, öğrencilerin ilgi
            duydukları alanlarda başarı elde etmelerine fırsat vermek de
            özgüvenlerini artırabilir. Özgüven, deneyimlerle gelişen bir
            özelliktir, bu nedenle öğrencilere çeşitli fırsatlar sunmak
            önemlidir.
          </p>
          <p>
            Etkili bir özgüvene sahip olmanın bazı belirgin özellikleri vardır.
            Bunlar arasında kendini tanıma, kendi yeteneklerinin farkında olma,
            hedef belirleme ve bu hedeflere ulaşmak için adım atmaya cesaret
            etme gibi özellikler bulunur. Ayrıca, başkalarının eleştirilerine
            açık olma ve hatalardan ders çıkarabilme yeteneği de önemlidir.
            Başarılarını kutlayabilme ve kendi başarılarından gurur duyabilme,
            etkili bir özgüvenin göstergelerindendir. Sonuç olarak, eğitimde
            özgüvenin önemi büyüktür. Özgüveni yüksek olan öğrenciler, daha
            başarılı olma eğilimindedir ve hayatları boyunca karşılaşacakları
            zorluklarla daha iyi başa çıkabilirler.
          </p>
        </Row>
      </Container>
    </section>
  );
}
