import { Container, Row } from "react-bootstrap";

export default function YasamKocuDestegi() {
  return (
    <section>
      <Container>
        <Row>
          <h2 className="text-center mb-3">Yaşam Koçu Desteği</h2>
          <img
            src="./images/yasamkocudestegi.png"
            alt="img-hero"
            className="img-fluid w-75 mx-auto mb-3"
          />
          <p className="w-75 mx-auto">
            Yaşam koçluğu, bireylerin yetenek ve potansiyellerini keşfetmeleri,
            hedef belirleme ve hedefe ulaşma sürecinde bireyin yoluna ışık
            tutan, kişiyi yaşamının her alanında destekleyen bir sistemdir.
            Yaşam koçu, bireylerin, yetenek ve potansiyellerine uygun hedefler
            belirlemesinde ve bu hedeflere ulaşırken yollarına çıkan gerçek ve
            düşünsel engelleri aşarak ilerlemesini sağlar. Bireye farkındalık
            kazandırarak konularla ilgili farklı bakış açıları sunar. Yaşam
            koçu, danışanların hedef belirleme, yaşam bilinci, farkındalık,
            kişisel gelişim ve kariyer planları üzerinde durarak destek
            sürecinde kişinin hayatındaki her alanı konu alır. Yaşam koçu,
            danışanlar ile bire bir ilgilenerek kişinin potansiyelini
            keşfetmesi, geliştirmesi, kişisel hedeflerini tanımlaması için doğru
            ve sağlıklı sorular sorar ve danışanı hedef belirleme ve hedeflerine
            yönelik harekete geçirmek için uygun stratejiler geliştirir. Yaşam
            koçunun bazı görevleri, kişinin hedeflerine ulaşması için bir çok
            bakış açısı geliştirmek, bireyin potansiyelini keşfederek maksimum
            düzeyde kullanabilmesine destek olmak, bireyi motive etmek,
            danışanın belirlenen hedeflerine, karakterine ve bakış açısına uygun
            eylem planları geliştirmek, danışanın sorumluluk bilincini
            arttırmaya yönelik eylemlerde bulunmak, doğru ve sağlıklı sorularla
            danışanın farkındalığının arttırılarak ihtiyaç duyduğu hedefe ve
            karara ulaşmasını sağlamak, bireylerin etkili, dengeli ve başarılı
            bir yaşam sürmesi için doğru karar alma, karar verme ve seçim yapma
            yetilerini geliştirmesini sağlamak.
          </p>
        </Row>
      </Container>
    </section>
  );
}
