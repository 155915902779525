import { Container, Row, Col } from "react-bootstrap";

export default function PostFour() {
  return (
    <section>
      <Container>
        <Row className="mx-auto">
          <h3 className="mb-3 w-75 mx-auto">
            Bireyler Sosyal Fobi: Sosyal Etkileşimlerdeki Korku ve Endişe
          </h3>
        </Row>
        <Row className="w-75 mx-auto">
          <Col className="d-flex align-items-center mb-3">
            <img
              src="../images/blogGamze.png"
              alt="img-hero"
              className="img-fluid rounded-circle"
              width={"35px"}
            />
            <span>&nbsp;Gamze Nur Akdemir</span>
          </Col>
          <img
            src="../images/socialProblem.png"
            alt="img-hero"
            className="img-fluid mx-auto mb-3"
          />

          <p>
            Sosyal fobi, sosyal etkileşimlerde veya toplum içinde olma
            durumlarında yoğun bir korku veya endişe hissiyle karakterize edilen
            bir anksiyete bozukluğudur. Sosyal fobi, kişinin diğer insanların
            önünde konuşma yapma, toplum önünde performans sergileme,
            başkalarıyla sohbet etme, toplu etkinliklere katılma veya hatta
            basit bir konuşmada bulunma gibi durumlarda aşırı stres yaşamasına
            neden olabilir. Bu durumda kişi sosyal olaylara katılma gibi
            aktivitelerden kaçınma eğilimi gösterir. Sosyal fobi, günlük yaşamı
            etkileyebilen bir anksiyete bozukluğu olarak kabul edilir. Sosyal
            fobiye sahip olan kişiler, sosyal durumların içinde kendilerini
            değerlendirildikleri, utandıkları veya eleştirildikleri hissine
            kapılabilirler. Bu durum, kendilerini rahatsız ve endişeli
            hissetmelerine neden olur. Sosyal fobi genellikle çocukluk veya
            ergenlik döneminde başlar ve yaşam boyu devam edebilir.
          </p>
          <p>
            Sosyal fobinin nedenleri tam olarak bilinmemekle birlikte, genetik
            faktörler, çevresel etkenler ve bireysel deneyimler etkili olabilir.
            Örneğin, ailede sosyal fobi hikayesi olan bireylerde sosyal fobi
            riski daha yüksek olabilir. Ayrıca, utanç veya aşağılanma gibi
            olumsuz sosyal deneyimler yaşayan kişilerde de sosyal fobi
            gelişebilir. Sosyal fobi, çeşitli belirtilerle kendini gösterebilir.
            Bunlar arasında yoğun anksiyete hissi, terleme, titreme, yüz
            kızarması, kalp çarpıntısı, nefes darlığı, mide bulantısı, baş
            dönmesi gibi fiziksel belirtiler bulunabilir. Aynı zamanda kişi,
            başkalarının kendisini değerlendireceği veya olumsuz düşüneceği
            korkusuyla birlikte aşırı bir öz bilinç hissi yaşar.
          </p>
          <p>
            Sosyal fobi genellikle tedavi edilebilir bir durumdur. En yaygın
            tedavi yöntemleri bilişsel davranış terapisi (BDT) ve ilaç
            tedavisidir. BDT, kişinin düşünce kalıplarını değiştirmesine ve
            endişeyle başa çıkma becerilerini geliştirmesine yardımcı olur. İlaç
            tedavisinde ise antidepresan gibi ilaçlar kullanılabilir. Sosyal
            fobiye sahip olan kişiler için destek ve anlayış önemlidir. Sosyal
            etkileşimlerle ilgili zorluklar yaşayan bireyler için zamanla
            rahatlama ve ilerleme sağlanabilir. Ayrıca, stresli durumlardan
            kaçınmak yerine, tedavi sürecinde sırayla daha zorlu durumlara maruz
            kalmak da önemli bir adımdır.
          </p>
          <p>Sosyal fobi ile başa çıkmak için yapılabilecekler şunlardır:</p>
          <ul>
            <li>
              Kaygı düzeyini azaltmaya yardımcı olacak derin nefes alma veya
              gevşeme tekniklerini öğrenmek.
            </li>
            <li>
              Sosyal durumları yavaş yavaş deneyimleyerek kendinize meydan
              okumak.
            </li>
            <li>Destek grubu veya terapist desteği aramak.</li>
            <li>
              Kendi değerlerinizi ve başarılarınızı hatırlamak ve olumsuz
              düşünceleri sorgulamak.
            </li>
            <li>Kendinize nazik davranmak ve kendinizi eleştirmemek.</li>
          </ul>
          <p>
            Unutmayın, sosyal fobi yaygın ve tedavi edilebilir bir durumdur.
            Profesyonel yardım aramak, semptomlarınızı anlamak ve başa çıkmak
            için ilk adımdır. Negatif düşüncelerinizi sorgulayın ve gerçekçi
            olmayan inançlarınızı tanıyın. Pozitif ve destekleyici bir iç
            konuşma geliştirmek için olumsuz düşünceleri yerine daha olumlu ve
            gerçekçi düşüncelerle değiştirmeyi deneyin. Sosyal fobisi olan diğer
            insanlarla iletişim kurabileceğiniz destek gruplarına katılmak,
            benzer deneyimler paylaşmak ve destek almak için faydalı olabilir.
            Sosyal fobinin üstesinden gelmek zaman alabilir, bu yüzden kendinize
            sabır gösterin ve küçük ilerlemeleri takdir edin.
          </p>
        </Row>
      </Container>
    </section>
  );
}
