import { Container, Row } from "react-bootstrap";

export default function VeliDanismanligiDestegi() {
  return (
    <section>
      <Container>
        <Row>
          <h2 className="text-center mb-3">Veli Danışmanlığı Desteği</h2>
          <img
            src="./images/yasamkocudestegi.png"
            alt="img-hero"
            className="img-fluid w-75 mx-auto mb-3"
          />
          <p className="w-75 mx-auto">
            Öğrencilerin kendilerini, istek ve beklentilerini keşfetmesi
            sürecinde eğitim, kariyer, sosyal ilişkiler, aile içi ilişkileri
            konusunda problemlerin yaşanması ve bu problemlerin eğitim öğretim
            ve gelecek hedeflerine yansıması riski durumunda veli ile öğrencinin
            eğitim ve hedefleri ele alınarak evde okulda aile içi
            ilişkilerimizde sosyal yaşamda nasıl bir davranış ve beklenti
            geliştirmek gerektiği veli ile görüşülerek sağlıklı bir ilerleme
            sağlanır.
          </p>
        </Row>
      </Container>
    </section>
  );
}
