import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import React from "react";
import Navigation from "./components/Navigation";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./components/Footer";
import Blog from "./pages/Blogs/Blog";
import PostOne from "./pages/Blogs/posts/PostOne";
import PostTwo from "./pages/Blogs/posts/PostTwo";
import PostThree from "./pages/Blogs/posts/PostThree";
import PostFour from "./pages/Blogs/posts/PostFour";
import NoPage from "./pages/NoPage";
import YasamKocuDestegi from "./pages/YasamKocuDestegi";
import EgitimOgrenciKocuDesteği from "./pages/EgitimOgrenciKocuDestegi";
import VeliDanismanligiDestegi from "./pages/VeliDanismanligiDestegi";
import KariyerKoclugu from "./pages/KariyerKoclugu";
import ProfesyonelKocluk from "./pages/ProfesyonelKocluk";
import EgitimDanismaniDestegi from "./pages/EgitimDanismaniDestegi";
import HizliOkumaAnlama from "./pages/HizliOkumaAnlama";
import HafizaTeknikleri from "./pages/HafizaTeknikleri";
import SpiritüelKocluk from "./pages/SpiritüelKocluk";
import PostFive from "./pages/Blogs/posts/PostFive";
import PostSix from "./pages/Blogs/posts/PostSix";
import PostSeven from "./pages/Blogs/posts/PostSeven";
import PostEight from "./pages/Blogs/posts/PostEight";

function App() {
  return (
    <>
      <header>
        <Navigation />
      </header>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route
          path="/blog/egitim-kocu-tanımlari-ve-görevleri"
          element={<PostOne />}
        />
        <Route
          path="/blog/basarili-bir-karar-verici-misin"
          element={<PostTwo />}
        />
        <Route path="/blog/karar-verirken-zorlanmak" element={<PostThree />} />
        <Route
          path="/blog/sosyal-etkilesimlerdeki-korku-endise"
          element={<PostFour />}
        />
        <Route
          path="/blog/bilsem-sınavını-kazanmanın-avantajları"
          element={<PostFive />}
        />
        <Route
          path="/blog/çocukların-öğrenme-stilini-keşfedin"
          element={<PostSix />}
        />
        <Route path="/blog/eğitim-hayatında-özgüven" element={<PostSeven />} />
        <Route
          path="/blog/yeni-dönem-öğrenci-etkili-hazırlık-süreci"
          element={<PostEight />}
        />
        <Route path="/hakkımızda" element={<About />} />
        <Route path="/iletişim" element={<Contact />} />
        <Route path="/yaşam-koçu-desteği" element={<YasamKocuDestegi />} />
        <Route
          path="/eğitim-öğrenci-koçu-desteği"
          element={<EgitimOgrenciKocuDesteği />}
        />
        <Route
          path="/veli-danışmanlığı-desteği"
          element={<VeliDanismanligiDestegi />}
        />
        <Route
          path="/kariyer-koçluğu-danışmanlık-desteği"
          element={<KariyerKoclugu />}
        />
        <Route
          path="/şirket-içi-eğitimler-profesyonel-koçluk-desteği"
          element={<ProfesyonelKocluk />}
        />
        <Route
          path="/eğitim-danışmanı-desteği"
          element={<EgitimDanismaniDestegi />}
        />
        <Route
          path="/hızlı-okuma-etkili-anlama-eğitimi"
          element={<HizliOkumaAnlama />}
        />
        <Route
          path="/hafıza-teknikleri-eğitimi"
          element={<HafizaTeknikleri />}
        />
        <Route path="/spiritüel-koçluk-desteği" element={<SpiritüelKocluk />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
