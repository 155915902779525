import { Container, Row } from "react-bootstrap";

export default function HafizaTeknikleri() {
  return (
    <section>
      <Container>
        <Row>
          <h2 className="text-center mb-3">Hafıza Teknikleri Eğitimi</h2>
          <img
            src="./images/hafizateknikleri.png"
            alt="img-hero"
            className="img-fluid w-75 mx-auto mb-3"
          />
          <p className="w-75 mx-auto">
            Hafıza teknikleri, sizleri tekrar etme derdinden kurtararak,
            zihninizin her noktasını harekete geçirir. Bilgilerinizi kalıcı
            depolamanızı ve yıllar sonra bile edindiğiniz bilgilerinize
            istediğiniz an kolayca hatırlayarak ulaşabilmenizi sağlar. Özellikle
            sınav hazırlık sürecinde öğrenciler hafıza teknikleri eğitimine
            katılarak öğrendikleri her bilgiyi hafızalarında kalıcı hale
            getirmeyi amaçlar. Ayrıca hafıza teknikleri eğitimi bireylerin
            algılama becerilerinin gelişimini sağlar. Özellikle yeni nesil sınav
            sistemi olan sözel mantık, sayısal mantık, konular arası ilişki
            kurma becerileri, bilgiyi kullanma ve yorum yapabilme becerilerini
            de büyük ölçüde arttırmaktadır. Birçok hafıza tekniği metodu mevcut
            olup kişilere algılama biçimlerine yani işitsel algılama, görsel
            algılama ve dokunsal algılama becerilerine göre öğretilmektedir.
          </p>
        </Row>
      </Container>
    </section>
  );
}
