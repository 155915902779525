import { Container, Row, Col } from "react-bootstrap";
export default function PostThree() {
  return (
    <section>
      <Container>
        <Row className="mx-auto">
          <h3 className="mb-3 w-75 mx-auto">Karar Verirken Zorlanmak</h3>
        </Row>
        <Row className="w-75 mx-auto">
          <Col className="d-flex align-items-center mb-3">
            <img
              src="../images/blogGamze.png"
              alt="img-hero"
              className="img-fluid rounded-circle"
              width={"35px"}
            />
            <span>&nbsp;Gamze Nur Akdemir</span>
          </Col>
          <img
            src="../images/difficultDecide.png"
            alt="img-hero"
            className="img-fluid mx-auto mb-3"
          />

          <p>
            Karar verirken zorlanıyor ve bu kararsızlık hali sizi olumsuz
            etkiliyorsa altında bir takım içsel problemler yatıyor olabilir.
            Örneğin özgüven problemi yaşıyorsanız kararınızın sürekli
            sorgulanmasından ve desteklenmemesinden endişe duyarsınız. Çocukluk
            çağında yaşanan travmalar akran zorbalığı istismar gibi durumlar
            özgüven düşüklüğüne sebep olabilir. Karar vermemizi zorlaştıran
            etkenlerden bir diğeri ise mükemmeliyetçiliktir. Tüm seçenekler bize
            yetersiz gelir ve daha fazla düşünmemiz gerektiğine kendimizi ikna
            ederiz. Bu duruma sürekli eleştiriye maruz kalmak veya ebeveynlerin
            yüksek beklentisi sebep olabilir. Aynı zamanda depresyon kaygı
            bozukluğu gibi psikolojik semptomlar da karar vermemizi güçleştiren
            etmenler arasındadır. Peki kararsızlıkla başa çıkmak için ne
            yapmalıyız? Öncelikli olarak karar vermeyi engelleyen duygunun ne
            olduğu tespit edilmeli. Daha sonra kendi istek ve duygularınızı ön
            planda tutarak karar verilecek durum tekrardan değerlendirilmelidir.
            Bu aşamada fayda zarar listesi yapmak size kolaylık sağlayacaktır.
            Böylelikle olası bir problemle karşılaşıldığında çözüm yollarını
            önceden belirlemiş oluruz. Tüm bunlara rağmen karar verme süreci
            sizin için aşırı stresli ve kaygılı geçiyorsa kararsızlığı oluşturan
            etmenleri daha sağlıklı bir şekilde aydınlatıp önlemek için mutlaka
            bir uzmana başvurmalısınız.
          </p>
        </Row>
      </Container>
    </section>
  );
}
