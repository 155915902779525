import { Container, Row, Col } from "react-bootstrap";
import { FiFacebook, FiInstagram, FiMap, FiPhone } from "react-icons/fi";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <footer>
      <Container fluid="true" className="bg-light p-3">
        <Row className="mx-auto d-flex align-items-center justify-content-around p-5">
          <Col sm={3} md={3} lg={3}>
            <Link to="/">
              <img
                src="./images/myadfdf.png"
                alt="img-logo"
                width={200}
                className="p-3 mx-auto"
              />
            </Link>
          </Col>
          <Col sm={3} md={3} lg={3}>
            <ul style={{ listStyleType: "none" }}>
              <h6>Sosyal Medya</h6>
              <li>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="https://www.facebook.com/Gamze.Nur.Akdemr?mibextid=9R9pXO"
                >
                  <FiFacebook style={{ color: "#16A5FB" }} />
                  &nbsp;Facebook
                </Link>
              </li>
              <li>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="https://www.instagram.com/mevzuyasam/?igshid=MzRlODBiNWFlZA%3D%3D"
                >
                  <FiInstagram style={{ color: "purple" }} />
                  &nbsp; Instagram
                </Link>
              </li>
            </ul>
          </Col>
          <Col sm={3} md={3} lg={3}>
            <ul style={{ listStyleType: "none" }}>
              <h6>İletişim</h6>
              <li>
                <FiMap style={{ color: "orange" }} /> &nbsp;Kentkoop Mah. 1865.
                Sokak Petunya Apt. A-1 No:7 Yenimahalle / Ankara
              </li>
              <li>
                <FiPhone />
                &nbsp;0507 466 06 18 - 0546 153 70 00
              </li>
            </ul>
          </Col>
        </Row>
        <hr />
        <div>
          <h6 className="text-center">
            <p>© 2022 - 2023 Tüm hakları saklıdır Mevzu Yaşam Akademi</p>
          </h6>
        </div>
      </Container>
    </footer>
  );
}
