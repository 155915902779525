import { Container, Row, Col } from "react-bootstrap";

export default function PostEight() {
  return (
    <section>
      <Container>
        <Row className="mx-auto">
          <h3 className="mb-3 w-75 mx-auto">
            Yeni Döneme Başlarken Öğrenciler İçin Etkili Hazırlık Süreci{" "}
          </h3>
        </Row>
        <Row className="w-75 mx-auto">
          <Col className="d-flex align-items-center mb-3">
            <img
              src="../images/blogGamze.png"
              alt="img-hero"
              className="img-fluid rounded-circle"
              width={"35px"}
            />
            <span>&nbsp;Gamze Nur Akdemir</span>
          </Col>
          <img
            src="../images/blog/yenidonem.jpg"
            alt="img-hero"
            className="img-fluid mx-auto mb-3"
          />

          <p>
            Yeni bir döneme başlarken öğrenciler için etkili bir hazırlık süreci
            şu adımları içerebilir:
          </p>
          <ul>
            <li>
              <b>Ders Programını İnceleme:</b> Ders programını önceden gözden
              geçirerek hangi derslerin, ne zaman olduğunu belirlemek önemlidir.
              Bu, öğrencinin haftalık veya aylık bir plan yapmasına yardımcı
              olabilir.
            </li>
            <li>
              <b>Malzemelerin Hazırlanması:</b> Gerekli kitaplar, defterler,
              kalem gibi malzemelerin önceden temin edilmesi, öğrencinin
              derslere odaklanmasını kolaylaştırır.
            </li>
            <li>
              <b>Hedef Belirleme:</b>Dönem başında belirli hedefler koymak,
              öğrencinin motivasyonunu artırabilir. Hem kısa vadeli hem de uzun
              vadeli hedefler belirlemek, başarıyı teşvik edebilir.
            </li>
            <li>
              <b>Zaman Yönetimine Özen Gösterme:</b> Ders çalışma, ödevler ve
              sosyal aktiviteler arasında denge sağlamak için etkili zaman
              yönetimi önemlidir. Bir günlük veya haftalık plan oluşturarak,
              zamanı verimli kullanabilirsin. Konuları Önceden İnceleme:
              Derslerin konularını önceden gözden geçirip temel bilgileri
              tazelemek, derslere daha hazırlıklı bir şekilde başlamana yardımcı
              olabilir.
            </li>
            <li>
              <b>Not Alma Alışkanlığı Edinme:</b> Derslerde önemli bilgileri not
              almak, öğrenmeyi güçlendirebilir. Derslerde dikkatlice dinlemek ve
              not almak, konuları daha iyi anlamana yardımcı olabilir.
            </li>
            <li>
              <b>İhtiyaç Duyulan Yardımı Alma:</b> Derslerle ilgili zorluk
              yaşandığında, öğretmenlerden veya sınıf arkadaşlarından yardım
              talep etmek önemlidir. Soruları sormaktan çekinmemek, öğrenme
              sürecini olumlu yönde etkileyebilir.
            </li>
            <li>
              <b>Fiziksel ve Zihinsel Sağlığına Özen Gösterme:</b> Düzenli
              egzersiz yapmak, yeterli uyku almak ve sağlıklı beslenmek,
              öğrencinin enerjisinin yüksek olmasına ve odaklanmasına yardımcı
              olabilir.
            </li>
          </ul>
          <p>
            Bu adımları takip ederek öğrenci, daha organize ve hazırlıklı bir
            şekilde yeni döneme başlayabilir.
          </p>
        </Row>
      </Container>
    </section>
  );
}
