import { Container, Row } from "react-bootstrap";

export default function SpiritüelKocluk() {
  return (
    <section>
      <Container>
        <Row>
          <h2 className="text-center mb-3"> Spiritüel Koçluk Desteği</h2>
          <img
            src="./images/spiritüel.png"
            alt="img-hero"
            className="img-fluid w-75 mx-auto mb-3"
          />
          <p className="w-75 mx-auto">
            Spiritüel koç, kişilerin iç huzur dengesini sağlayarak yaşamda sahip
            olmak istediklerine, olmak istedikleri yerlere, pozitif bakış açısı,
            ruhsal sakinlik ile negatif yaklaşımlardan arınarak isteklerini ve
            hayallerini elde etmesi ve kişinin hedeflerine ulaşmasına destek
            olmayı amaçlar. Spiritüel koç, kişinin hedeflerine ulaşmasını
            engelleyen içsel çatışmalarının kaynağını belirler, kişinin
            beklentilerini keşfederek hedefe ulaşma yolunda zihinsel ve ruhsal
            rahatlık, sakinlik ve pozitif düşünce ile ilerleyebilmeyi amaçlar ve
            buna yönelik çalışmalarda bulunur.
          </p>
        </Row>
      </Container>
    </section>
  );
}
