import { Container, Col, Row } from "react-bootstrap";
import {
  FiFacebook,
  FiInstagram,
  FiTwitter,
  FiMap,
  FiPhone,
} from "react-icons/fi";
export default function Contact() {
  return (
    <section className="mx-auto">
      <Container className="w-100 mt-5">
        <Row className="mx-auto">
          <Col>
            <ul style={{ listStyleType: "none" }}>
              <h1 className="text-primary">İletişim Bilgileri</h1>
              <iframe
                title="This is a unique title"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.521260322283!2d106.8195613507864!3d-6.194741395493371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f5390917b759%3A0x6b45e67356080477!2sPT%20Kulkul%20Teknologi%20Internasional!5e0!3m2!1sen!2sid!4v1601138221085!5m2!1sen!2sid"
                aria-hidden="false"
                tabIndex="0"
                className="w-100"
              />
              <li>
                {" "}
                <FiMap />
                &nbsp;Kentkoop Mah. 1865. Sokak Petunya Apt. A-1 No:7
                Yenimahalle / Ankara
              </li>
              <li>
                <FiPhone />
                &nbsp;0507 466 06 18 - 0546 153 70 00
              </li>
              <h6 className="mt-3">Bizi Sosyal Medyamızdan Takip Edin</h6>
              <li>
                <FiFacebook />
                &nbsp;Facebook
              </li>
              <li>
                <FiInstagram />
                &nbsp;Instagram
              </li>
              <li>
                <FiTwitter />
                &nbsp;Twitter
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
