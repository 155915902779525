import { Container, Row } from "react-bootstrap";

export default function ProfesyonelKocluk() {
  return (
    <section>
      <Container>
        <Row>
          <h2 className="text-center mb-3">
            Şirket Hizmet İçi Eğitimler ve Profesyonel Koçluk Desteği
          </h2>
          <img
            src="./images/profesyonelyasamkocudestegi.png"
            alt="img-hero"
            className="img-fluid w-75 mx-auto mb-3"
          />
          <p className="w-75 mx-auto">
            Personelin veya profesyonel hayatta ki kişilerin ve içinde
            bulundukları şirket ve departmanların ihtiyaçlarını ve
            beklentilerini tespit ederek uygunluklarını saptamak ve çeşitli iş
            ve bireysel analizleri gerçekleştirerek kişinin ve şirketin kariyer
            sürecinde gelişim alanlarını belirlemek ve karşılıklı geliştirmek,
            personel potansiyelini tespit etmek ve farkındalığını arttırmak
            üzere düzenli olarak görüşmeler yapmak, kariyer yönetimi
            süreçlerinde çalışanın veya profesyonel hayata geçiş yapacak kişinin
            eğitim veya gelişim faaliyetlerinin belirlenmesi, şirket ve personel
            bağlılığının artırılmasına yönelik çalışmalar yapmak ve hizmet içi
            oryantasyon, motivasyon, adaptasyon vb. alanlarda eğitimler,
            seminerler düzenleyerek kariyer ve performans süreçlerinin maksimum
            verimle yürütülmesinde departman yöneticilerine, çalışanlara ve
            profesyonel hayata giriş yapacak bireylere takım koçluğu, yönetici
            koçluğu, şirket koçluğu, kariyer koçluğu alanlarında destek
            vermekteyiz.
          </p>
        </Row>
      </Container>
    </section>
  );
}
