import { Container, Row, Col, Button } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LiaChalkboardTeacherSolid } from "react-icons/lia";

export default function Home() {
  return (
    <>
      <main>
        <Container>
          <Row className="d-flex align-items-center justify-content-center mt-5 mb-5 mx-auto gap-5">
            <Col sm={8} md={6} lg={6} xl={6}>
              <h1 style={{ fontFamily: "Poppins" }}>
                <b>Hedef belirleme ve hedefe ulaşma sürecinde yanınızdayız.</b>
              </h1>
              <p style={({ fontFamily: "Poppins" }, { fontSize: "18px" })}>
                Koçluk, istenilen hedefe ulaşmak için, koç ve danışan arasında
                kurulan planlı bir gelişim ilişkisidir. Kişilerin var olan
                potansiyelini ve yeteneklerini ortaya çıkararak, ulaşılabilir
                hedeflere odaklanan bir öğrenme ve gelişim sürecidir.
              </p>
              <Link to="/iletişim">
                <Button
                  className="btn px-5"
                  style={{ backgroundColor: "#15BEAE", borderColor: "#15BEAE" }}
                >
                  <b>İletişim</b>
                </Button>
              </Link>
            </Col>
            <Col sm={4} md={5} lg={5} xl={5}>
              <img
                fluid="true"
                src="./images/succession.png"
                alt="img-hero"
                className="w-100 img-fluid"
              />
            </Col>
          </Row>
          <Row className="mb-5">
            <Carousel hover="false" data-bs-theme="dark">
              <Carousel.Item>
                <h5
                  style={{
                    backgroundColor: "#F4E3E3",
                    borderRadius: "10px",
                    width: "fit-content",
                    padding: "14px",
                  }}
                  className="text-center mb-5 mx-auto"
                >
                  Yaşam Koçu Desteği
                </h5>
              </Carousel.Item>
              <Carousel.Item>
                <h5
                  style={{
                    backgroundColor: "#A8DBAA",
                    borderRadius: "10px",
                    width: "fit-content",
                    padding: "14px",
                  }}
                  className="text-center mb-5 mx-auto"
                >
                  Eğitim ve Öğrenci Koçu Desteği
                </h5>
              </Carousel.Item>
              <Carousel.Item>
                <h5
                  style={{
                    backgroundColor: "#C0C1F0",
                    borderRadius: "10px",
                    width: "fit-content",
                    padding: "14px",
                  }}
                  className="text-center mb-5 mx-auto"
                >
                  Eğitim Danışmanı Desteği
                </h5>
              </Carousel.Item>
              <Carousel.Item>
                <h5
                  style={{
                    backgroundColor: "#F7F99D",
                    borderRadius: "10px",
                    width: "fit-content",
                    padding: "14px",
                  }}
                  className="text-center mb-5 mx-auto"
                >
                  Kariyer Koçu Desteği
                </h5>
              </Carousel.Item>
              <Carousel.Item>
                <h5
                  style={{
                    backgroundColor: "#EDF3F3",
                    borderRadius: "10px",
                    width: "fit-content",
                    padding: "14px",
                  }}
                  className="text-center mb-5 mx-auto"
                >
                  Hizmet İçi Eğitimler ve Profesyonel Koçluk Desteği
                </h5>
              </Carousel.Item>
              <Carousel.Item>
                <h5
                  style={{
                    backgroundColor: "#F4E3E3",
                    borderRadius: "10px",
                    width: "fit-content",
                    padding: "14px",
                  }}
                  className="text-center mb-5 mx-auto"
                >
                  Veli Danışmanlığı Desteği
                </h5>
              </Carousel.Item>
              <Carousel.Item>
                <h5
                  style={{
                    backgroundColor: "#A8DBAA",
                    borderRadius: "10px",
                    width: "fit-content",
                    padding: "14px",
                  }}
                  className="text-center mb-5 mx-auto"
                >
                  Hızlı Okuma ve Etkili Anlama Eğitimi
                </h5>
              </Carousel.Item>
              <Carousel.Item>
                <h5
                  style={{
                    backgroundColor: "#C0C1F0",
                    borderRadius: "10px",
                    width: "fit-content",
                    padding: "14px",
                  }}
                  className="text-center mb-5 mx-auto"
                >
                  Hafıza Teknikleri Eğitimi
                </h5>
              </Carousel.Item>
              <Carousel.Item>
                <h5
                  style={{
                    backgroundColor: "#F7F99D",
                    borderRadius: "10px",
                    width: "fit-content",
                    padding: "14px",
                  }}
                  className="text-center mb-5 mx-auto"
                >
                  Spiritüel Koçluk Desteği
                </h5>
              </Carousel.Item>
            </Carousel>
          </Row>
          <Row className="mb-3 mx-auto">
            <Col>
              <h2 className="text-center mb-4">
                Kullanılamayan Potansiyel ve Keşfedilememiş Yetenekler Ruha
                Yüktür.
              </h2>
              <p className="p-3">
                Kişisel Gelişim Uzmanı, Profesyonel Koç ve Danışman Gamze Nur
                Akdemir öncülüğünde sizlere yaşamın her alanındaki konularla
                ilgili yardımcı olmayı hedefliyoruz. Koçluk, danışmanlık,
                öğrenci özel ders desteği, sağlıklı ve dengeli, profesyonel
                yaşam konusunda aklınızdaki tüm sorularınıza ışık tutmanızı
                sağlıyor, eğitimlerimizle ve seanslarımızla sizleri
                destekliyoruz. Eğitim koçluğu alanımızda bireysel öğrenci
                analizi, bireysel program ve takibi, bireysel verimli ve doğru
                çalışma metotları, veli geri bildirimi, algılama çalışmaları,
                sözel ve sayısal mantık, hızlı okuma ve hafıza teknikleri, sınav
                kaygısı kontrolü, zaman yönetimi, dikkat dağınıklığı ve
                odaklanama sorunu desteği ile öğrencilerin hedeflerine daha
                sağlıklı ulaşmalarını sağlıyoruz. Eğitim koçluğu
                danışanlarımızın hedef belirleme ve hedefe ulaşma sürecinde
                sağlıklı karar alma, başarı takip çizelgeleri ile veli
                danışmanlığı sistemimiz ile çalışmalarını ve hedeflerini
                kontrollü destekliyor hedefe ulaşma süreçlerinde ailelerimize de
                kolaylık sağlıyoruz. Profesyonel hayatınızda ise iş ve şirket
                ilişkilerinizde, oryantasyon, adaptasyon, motivasyon gibi
                alanlarda hizmet içi eğitimler sağlıyor profesyonel ve kariyer
                hayatı tercih sürecinizde kariyer koçluğu desteği sağlıyoruz.
              </p>
            </Col>
          </Row>
        </Container>
      </main>

      <section id="galeri">
        <Container className="d-flex justify-content-center mb-3">
          <Row className="justify-content-center">
            <Col>
              <Carousel className="mx-auto w-100">
                <Carousel.Item>
                  <img
                    className="img-fluid d-block w-100"
                    alt="img-hero"
                    src="./images/galeri/image-29.jpg"
                    style={{
                      objectFit: "cover",
                      maxHeight: "650px",
                    }}
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="img-fluid d-block w-100"
                    alt="img-hero"
                    src="./images/galeri/image-30.jpg"
                    style={{
                      objectFit: "cover",
                      maxHeight: "650px",
                    }}
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="img-fluid d-block w-100"
                    alt="img-hero"
                    src="./images/galeri/image-33.jpg"
                    style={{
                      objectFit: "cover",
                      maxHeight: "650px",
                    }}
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="img-fluid d-block w-100"
                    alt="img-hero"
                    src="./images/galeri/image-7.jpg"
                    style={{
                      objectFit: "cover",
                      maxHeight: "650px",
                    }}
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="img-fluid d-block w-100"
                    alt="img-hero"
                    src="./images/galeri/image-34.jpg"
                    style={{
                      objectFit: "cover",
                      maxHeight: "650px",
                    }}
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="img-fluid d-block w-100"
                    alt="img-hero"
                    src="./images/galeri/image-35.jpg"
                    style={{
                      objectFit: "cover",
                      maxHeight: "650px",
                    }}
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="img-fluid d-block w-100"
                    alt="img-hero"
                    src="./images/galeri/image-38.jpg"
                    style={{
                      objectFit: "cover",
                      maxHeight: "650px",
                    }}
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="img-fluid d-block w-100"
                    alt="img-hero"
                    src="./images/galeri/image-37.jpg"
                    style={{
                      objectFit: "cover",
                      maxHeight: "650px",
                    }}
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="img-fluid d-block w-100"
                    alt="img-hero"
                    src="./images/galeri/image-36.jpg"
                    style={{
                      objectFit: "cover",
                      maxHeight: "650px",
                    }}
                  />
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row style={{ color: "#FF5A2F" }}>
            <Col className="d-flex align-items-center">
              <LiaChalkboardTeacherSolid size={50} />
              <h2 className="m-0 mt-3 mb-3">Hizmetlerimiz</h2>
            </Col>
          </Row>

          <Row>
            <Col className="pb-3" sm={4} md={6} lg={4} xl={4}>
              <Card style={{ width: "19rem" }} className="mx-auto d-flex">
                <Card.Img
                  variant="top"
                  className="img-fluid"
                  src="./images/yasamkocudestegi.png"
                />
                <Card.Body>
                  <Card.Title>Yaşam Koçu Desteği</Card.Title>
                  <Card.Text>
                    Yaşam koçluğu, danışan kişinin potansiyelinin keşfedilmesi
                    ve üst limitlere çıkması sağlanarak yaşam hedeflerine
                    ulaşmasını amaçlayan profesyonel bir yaşam takip ve destek
                    sistemidir.
                  </Card.Text>
                  <Link to="/yaşam-koçu-desteği">
                    <Button
                      variant="primary"
                      style={{
                        backgroundColor: "#15BEAE",
                        borderColor: "#15BEAE",
                      }}
                    >
                      Devamını Oku
                    </Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col className="pb-3" sm={4} md={6} lg={4} xl={4}>
              <Card style={{ width: "19rem" }} className="mx-auto">
                <Card.Img variant="top" src="./images/egitimkocudestegi.png" />
                <Card.Body>
                  <Card.Title>Eğitim ve Öğrenci Koçu Desteği</Card.Title>
                  <Card.Text>
                    Eğitim koçu, danışanlarının akademik hayatında başarılı
                    birer birey olması adına eğitim ve öğretim süresince
                    öğrencisine destek olur.
                  </Card.Text>
                  <Link to="eğitim-öğrenci-koçu-desteği">
                    <Button
                      variant="primary"
                      style={{
                        backgroundColor: "#15BEAE",
                        borderColor: "#15BEAE",
                      }}
                    >
                      Devamını Oku
                    </Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col className="pb-3 mt-4" sm={4} md={6} lg={4} xl={4}>
              <Card style={{ width: "19rem" }} className="mx-auto">
                <Card.Img
                  variant="top"
                  src="/images/velidanismanidestegi.png"
                />
                <Card.Body>
                  <Card.Title>Veli Danışmanlığı Desteği</Card.Title>
                  <Card.Text>
                    Veli ile öğrencinin eğitim ve hedefleri ele alınarak evde
                    okulda ve sosyal yaşamda nasıl bir davranış ve beklenti
                    geliştirmek gerektiği tespit edilir.
                  </Card.Text>
                  <Link to="/veli-danışmanlığı-desteği">
                    <Button
                      variant="primary"
                      style={{
                        backgroundColor: "#15BEAE",
                        borderColor: "#15BEAE",
                      }}
                    >
                      Devamını Oku
                    </Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="d-flex align-items-center justify-content-center mx-auto">
            <Col className="pb-3" sm={4} md={6} lg={4} xl={4}>
              <Card style={{ width: "19rem" }} className="mx-auto">
                <Card.Img variant="top" src="/images/kariyerkocudestegi.png" />
                <Card.Body>
                  <Card.Title>
                    Kariyer Koçluğu ve Danışmanlık Desteği
                  </Card.Title>
                  <Card.Text>
                    Kariyer koçu, profesyonel hayata geçiş yapan bireylerin
                    kariyer basamaklarına istediği mesleğe ve profesyonel
                    hedeflerine ulaşmasına destek olur.
                  </Card.Text>
                  <Link to="kariyer-koçluğu-danışmanlık-desteği">
                    <Button
                      variant="primary"
                      style={{
                        backgroundColor: "#15BEAE",
                        borderColor: "#15BEAE",
                      }}
                    >
                      Devamını Oku
                    </Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col className="pb-3" sm={4} md={6} lg={4} xl={4}>
              <Card style={{ width: "19rem" }} className="mx-auto">
                <Card.Img
                  variant="top"
                  src="/images/profesyonelyasamkocudestegi.png"
                />
                <Card.Body>
                  <Card.Title>
                    Şirket Hizmet İçi Eğitimler ve Profesyonel Koçluk Desteği
                  </Card.Title>
                  <Card.Text>
                    Personelin veya profesyonel hayatta ki kişilerin ve içinde
                    bulundukları şirket ve departmanların ihtiyaçlarını ve
                    beklentilerini tespit eder...
                  </Card.Text>
                  <Link to="/şirket-içi-eğitimler-profesyonel-koçluk-desteği">
                    <Button
                      variant="primary"
                      style={{
                        backgroundColor: "#15BEAE",
                        borderColor: "#15BEAE",
                      }}
                    >
                      Devamını Oku
                    </Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col className="pb-3" sm={4} md={6} lg={4} xl={4}>
              <Card style={{ width: "19rem" }} className="mx-auto">
                <Card.Img
                  variant="top"
                  src="./images/egitimdanismanidestegi.png"
                />
                <Card.Body>
                  <Card.Title>Eğitim Danışmanı Desteği</Card.Title>
                  <Card.Text>
                    Öğrencinin hedefe ulaşma sürecinde eğitim ve öğretimle
                    ilgili her atacağı adımda, uzman eğitim danışmanlarına
                    danışarak ilerlemesi kişinin hedefe daha emin ve sağlıklı
                    adımlarla ulaşmasını sağlar.
                  </Card.Text>
                  <Link to="/eğitim-danışmanı-desteği">
                    <Button
                      variant="primary"
                      style={{
                        backgroundColor: "#15BEAE",
                        borderColor: "#15BEAE",
                      }}
                    >
                      Devamını Oku
                    </Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="d-flex align-items-center justify-content-center mx-auto">
            <Col className="pb-3" sm={4} md={6} lg={4} xl={4}>
              <Card style={{ width: "19rem" }} className="mx-auto">
                <Card.Img variant="top" src="/images/hizliokuma.png" />
                <Card.Body>
                  <Card.Title>Hızlı Okuma ve Etkili Anlama Eğitimi</Card.Title>
                  <Card.Text>
                    Hızlı okuma ve etkili anlama uzmanlarımızın eğitimleriyle
                    kısa zamanda algınızı güçlendirecek, hafızanızı doğru
                    kullanma stratejilerini öğrenecek, göz koordinasyonunuzu
                    sağlayacak...
                  </Card.Text>
                  <Link to="/hızlı-okuma-etkili-anlama-eğitimi">
                    <Button
                      variant="primary"
                      style={{
                        backgroundColor: "#15BEAE",
                        borderColor: "#15BEAE",
                      }}
                    >
                      Devamını Oku
                    </Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col className="pb-3" sm={4} md={6} lg={4} xl={4}>
              <Card style={{ width: "19rem" }} className="mx-auto">
                <Card.Img variant="top" src="/images/hafizateknikleri.png" />
                <Card.Body>
                  <Card.Title>Hafıza Teknikleri Eğitimi</Card.Title>
                  <Card.Text>
                    Hafıza teknikleri, sizleri tekrar etme derdinden kurtararak,
                    zihninizin her noktasını harekete geçirir. Bilgilerinizi
                    kalıcı depolamanızı ve yıllar sonra bile edindiğiniz
                    bilgilerinize istediğiniz an kolayca hatırlayarak
                    ulaşabilmenizi sağlar.
                  </Card.Text>
                  <Link to="/hafıza-teknikleri-eğitimi">
                    <Button
                      variant="primary"
                      style={{
                        backgroundColor: "#15BEAE",
                        borderColor: "#15BEAE",
                      }}
                    >
                      Devamını Oku
                    </Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col className="pb-3" sm={4} md={6} lg={4} xl={4}>
              <Card style={{ width: "19rem" }} className="mx-auto">
                <Card.Img variant="top" src="./images/spiritüel.png" />
                <Card.Body>
                  <Card.Title>Spiritüel Koçluk Desteği</Card.Title>
                  <Card.Text>
                    Spiritüel koç, kişilerin iç huzur dengesini sağlayarak
                    yaşamda sahip olmak istediklerine, olmak istedikleri
                    yerlere, pozitif bakış açısı, ruhsal sakinlik ile negatif
                    yaklaşımlardan arınarak isteklerini ve hayallerini elde
                    etmesi ve kişinin hedeflerine ulaşmasına destek olmayı ...
                  </Card.Text>
                  <Link to="/spiritüel-koçluk-desteği">
                    <Button
                      variant="primary"
                      style={{
                        backgroundColor: "#15BEAE",
                        borderColor: "#15BEAE",
                      }}
                    >
                      Devamını Oku
                    </Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <img
              className="img-fluid mt-5 mb-5"
              src="./images/galeri/banner-1.png"
              alt="img-hero-banner"
            />
          </Row>
        </Container>
      </section>
    </>
  );
}
