import { Container, Row, Col } from "react-bootstrap";

export default function PostSix() {
  return (
    <section>
      <Container>
        <Row className="mx-auto">
          <h3 className="mb-3 w-75 mx-auto">
            Çocuklarımızın Algılama Biçimini ve Öğrenme Stilini Keşfederek
            Başarı Sağlıyoruz
          </h3>
        </Row>
        <Row className="w-75 mx-auto">
          <Col className="d-flex align-items-center mb-3">
            <img
              src="../images/blogGamze.png"
              alt="img-hero"
              className="img-fluid rounded-circle"
              width={"35px"}
            />
            <span>&nbsp;Gamze Nur Akdemir</span>
          </Col>
          <img
            src="../images/blog/kaygıcocuk.jpg"
            alt="img-hero"
            className="img-fluid mx-auto mb-3"
          />

          <p>
            Çocuğunuz hayatı nasıl algılıyor? Derslerini, anne babasını, sosyal
            hayatını, yaşadığı olaylara durumlara nasıl anlam yüklüyor? Bilmek
            ister misiniz? Neden ısrarla bir derste başarısız olmakta kararlı?
            Neden sayısalı kotuyken sözel dersleri daha iyi? Peki neden başka
            bir çocuk her iki alanda da başarı sağlayabiliyor? Onun algılama
            biçimi nedir? Ders çalışırken nasıl algıladığını, sizinle konuşurken
            nelere dikkat ettiğini, dokunsal mı görsel mı yoksa işitsel mi
            hafıza ile anılarını kaydettiğini, neden ders çalışırken bazı
            çocukların sizi yanında istediğini, neden asosyal davranışlar
            sergilediğini veya fazla sosyal olmak arzusunu, neden anladım dediği
            dersi unutarak başarısız olduğunu bilmek ister misiniz?{" "}
          </p>
          <p>
            {" "}
            <b>
              Hemen ücretsiz algılama testlerimiz için randevunuzu oluşturun.
              Birlikte çocuğunuzun dünyasını keşfedelim 🙏💐
            </b>
          </p>
          <p>
            Okulda zorbalık genellikle belirli işaretlerle anlaşılabilir.
            Bunlar: Çocuğunuzun vücudunda belirgin morluklar, çürükler veya
            yaralar varsa, bu fiziksel zorbalığın bir işareti olabilir.
            Çocuğunuz ani duygusal değişiklikler yaşıyorsa (endişe, hüzün, öfke
            gibi), kendine güvensizlik veya utanç duyuyorsa, zorbalık olabilir.
            Çocuğunuzun okuldan kaçınması, sosyal etkinliklerden uzak durması,
            arkadaşlarını kaybetmesi veya izole olması zorbalığın bir işareti
            olabilir. Zorbalık mağduru olan çocukların ders performansı
            genellikle düşer. Okulda konsantre olmakta zorlanabilirler veya
            okuldan kaçınabilirler. Çocuğunuzun eşyalarının kaybolması, hasar
            görmesi veya ani paranoyak davranışlar sergilemesi, zorbalığa maruz
            kaldığını gösterebilir. Eğer çocuğunuzda bu tür belirtiler fark
            ederseniz, konuyu dikkatlice ele almak ve uzmanlarla iletişime
            geçmek önemlidir.
          </p>
        </Row>
      </Container>
    </section>
  );
}
