import { Container, Row, Col } from "react-bootstrap";
export default function PostTwo() {
  return (
    <section>
      <Container>
        <Row className="mx-auto">
          <h3 className="mb-3 w-75 mx-auto">
            Başarılı Bir Karar Verici Misin?
          </h3>
        </Row>
        <Row className="w-75 mx-auto">
          <Col className="d-flex align-items-center mb-3">
            <img
              src="../images/blogGamze.png"
              alt="img-hero"
              className="img-fluid rounded-circle"
              width={"35px"}
            />
            <span>&nbsp;Gamze Nur Akdemir</span>
          </Col>
          <img
            src="../images/decide.png"
            alt="img-hero"
            className="img-fluid mx-auto mb-3"
          />

          <p>
            Yaşantımız boyunca önemli önemsiz, büyük küçük, değerli değersiz bir
            çok konuda karar vermek zorundayız. Peki karar vermek nedir ve karar
            verirken neyi dikkate almalıyız? Acaba bizler sadece doğru karar
            verdiğimizde mi bir karar vermiş oluruz? Peki ya vermiş olduğumuz
            kararlar yanlışsa? Sonuca nasıl katlanıyoruz? Psikolojiye göre karar
            vermek, bir fikrin veya hareketin mümkün diğer seçenek arasından
            seçilmesiyle sonuçlanan zihinsel bir işlem olarak dikkate alınır.
            Her karar verme işlemi mutlaka nihai bir seçim ortaya koyar.
            Yani,karar vermek, karar verenin değerlerine göre kendi
            alternatiflerini belirlemesi bu alternatifler arasından bir seçim
            yapmasıdır.
          </p>
          <p>
            Karar ne kadar bir sonuç olsa da karar verebilmek aslında başlı
            başına bir süreçtir. Evet,danışanlarımız karar vermek konusunda
            ‘’Ben karar veremiyorum, karar versem de sonunda aslında hedefime
            uygun kararı vermiş olmuyorum’’ diyor.Bu durum ise kişide özsaygı ve
            özgüvenin azalmasına neden oluyor. İşte tam olarak bizler yaşam
            koçları olarak tam bu sürecte sizlerin yanında ölüyoruz. Peki bir de
            şu açıdan bakalim,karar verecek birey karar verme sürecinde kendini
            ne kadar iyi tanıyor? Kişi, kararının kendisine ne kazandırmasını
            bekliyor? Bu karar kişinin hayatında onu hangi aşamaya taşıyacak ya
            da kararının sonucunda yine aynı yerde kalabilmeyi mi hedefliyor?
          </p>
          <p>
            Karar verirken kişiyi etkileyen çevresel faktörler var mı? Hepimiz
            anlamış olmalıyız ki aslında karar vermek sürecinde en önemli nokta
            kendimizi ne kadar iyi tanıdığımız, durumdan ne beklediğimiz ve
            karar sürecimizde çevrenin bizi ne kadar etkilediğidir. Anlık veya
            uzun süreli verilen kararlarda ne kadar başarılı sonuç elde
            ediyoruz. Peki durumlar karşısında karar vermemek beklemek gibi bi
            tercihimiz var mı? Varsa bunu kullanmanın belki bize daha çok getiri
            sağlayacağının farkında mıyız? Tüm bu aşmaları ve süreci nasıl
            yönetiyoruz? Sen...İyi bir karar verici misin? Yoksa çevre
            tarafından bastırılmış bir kişiliğe sahip, olaylar ve durumlar
            karşısında karar verme yetini başarılı kullanamıyor musun? Başarılı
            bir karar verici olmak için ilk önce kendini tanımaya hazır mısın?
            Farket..
          </p>
        </Row>
      </Container>
    </section>
  );
}
