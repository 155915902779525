import { Container, Row, Col } from "react-bootstrap";

export default function PostFive() {
  return (
    <section>
      <Container>
        <Row className="mx-auto">
          <h3 className="mb-3 w-75 mx-auto">
            BİLSEM Sınavını Kazanmanın Avantajları
          </h3>
        </Row>
        <Row className="w-75 mx-auto">
          <Col className="d-flex align-items-center mb-3">
            <img
              src="../images/blogGamze.png"
              alt="img-hero"
              className="img-fluid rounded-circle"
              width={"35px"}
            />
            <span>&nbsp;Gamze Nur Akdemir</span>
          </Col>
          <img
            src="../images/blog/sınav.jpg"
            alt="img-hero"
            className="img-fluid mx-auto mb-3"
          />

          <p>
            Öğrenciler, zenginleştirilmiş eğitim programlarına katılma fırsatı
            bulurlar. Bu programlar genellikle normal müfredattan daha ileri
            seviyede konuları içerir ve öğrencilere daha derinlemesine bir
            öğrenme deneyimi sunar.
          </p>
          <p>
            Kazanan öğrenciler, bilimsel ve matematiksel becerilerini geliştirme
            ve daha ileri seviyede akademik çalışmalara hazırlanma şansı elde
            ederler. Bu da onların gelecekteki eğitim ve kariyer olanaklarını
            artırabilir.
          </p>
          <p>
            BİLSEM programları, öğrencilere laboratuvar deneyimi, proje tabanlı
            öğrenme ve uzman öğretmenler eşliğinde desteklenmiş öğrenme gibi
            fırsatlar sunar. Bu da öğrencilerin kendi ilgi alanlarını
            keşfetmelerine ve yeteneklerini geliştirmelerine yardımcı olur.
          </p>
          <p>
            Kazanan öğrenciler, çeşitli akademik etkinliklere ve yarışmalara
            katılma şansı elde edebilirler. Bu tür etkinlikler, öğrencilerin
            kendilerini daha fazla geliştirmelerine ve potansiyellerini
            maksimize etmelerine olanak tanır.
          </p>
        </Row>
      </Container>
    </section>
  );
}
