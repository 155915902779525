import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import "./navigation.css";

export default function Navigation() {
  return (
    <>
      <Navbar bg="light" data-bs-theme="light" className="mb-5">
        <Container>
          <Nav className="mx-auto p-2">
            <NavLink
              to="/"
              className={"px-2, text-decoration"}
              style={({ isActive }) => ({
                color: isActive ? "blue" : "black",
              })}
            >
              Anasayfa
            </NavLink>
            <NavLink
              to="/blog"
              className={"px-2, text-decoration"}
              style={({ isActive }) => ({
                color: isActive ? "blue" : "black",
              })}
            >
              Blog
            </NavLink>
            <NavLink
              to="/hakkımızda"
              className={"px-2, text-decoration"}
              style={({ isActive }) => ({
                color: isActive ? "blue" : "black",
              })}
            >
              Hakkımızda
            </NavLink>
            <NavLink
              to="/iletişim"
              className={"px-2, text-decoration"}
              style={({ isActive }) => ({
                color: isActive ? "blue" : "black",
              })}
            >
              İletişim
            </NavLink>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}
