import { Container, Row } from "react-bootstrap";
export default function EgitimOgrenciKocuDesteği() {
  return (
    <section>
      <Container>
        <Row>
          <h2 className="text-center mb-3">Eğitim ve Öğrenci Koçu Desteği</h2>
          <img
            src="./images/egitimkocudestegi.png"
            alt="img-hero"
            className="img-fluid w-75 mx-auto mb-3"
          />
          <p className="w-75 mx-auto">
            Eğitim koçu, danışanlarının akademik hayatında başarılı birer birey
            olması adına eğitim ve öğretim süresince öğrencisine destek olur.
            Kişiye bireysel analizler yaparak öğrencilere uygun program hazırlar
            ve verimli çalışma stratejileri sunar, danışanını hedef belirleme
            sürecine alarak potansiyel ve yeteneklerini keşfetmesini sağlar,
            belirlenen gelecek hedeflerine ulaşmasını destekler. Öğrenci genel
            durumu ile ilgili gerek velilere gerek öğrencilere geri bildirim
            sağlayarak hedefe ulaşma sürecinde alınması gereken önlemler ve
            destekler konusunda kişileri mutlaka bilgilendirir. Özellikle
            herhangi bir sınav sürecinde (gerek okul gerek LGS – YKS gibi lise
            ve üniversite geçiş sınavları) öğrencilerin yaşadığı zaman yönetimi,
            stres yönetimi, sınav kaygısı yönetimi, bireysel algılama
            çalışmaları, verimli ve düzenli ders çalışma, bireysel takip ve
            kontrol, hafıza teknikleri eğitimleri, hızlı okuma ve etkili anlama,
            deneme analizleri vb. alanlarda destekleyerek ilerler. Öğrencinin
            kendi potansiyelini keşfetmesi, hedeflerini belirlemesi, özgüven ve
            öz inanç kazanmasına yardımcı olur, sorumluluk bilincinin artmasına
            destek olur, potansiyel ve yeteneğine uygun mesleklere yönlendirir,
            kişiye motivasyon sağlar, hedefe ulaşma sürecinde aile ve sosyal
            çevresi ile ilgili sorunları keşfederek gidermesi için kişiye yol
            gösterir.
          </p>
        </Row>
      </Container>
    </section>
  );
}
