import { Container, Row, Col } from "react-bootstrap";
export default function PostOne() {
  return (
    <section>
      <Container>
        <Row className="mx-auto">
          <h3 className="mb-3 w-75 mx-auto">
            Eğitim ve Öğrenci Koçu Tanımı Görevleri ve Olması Gereken Özellikler
            Nelerdir ?
          </h3>
        </Row>
        <Row className="w-75 mx-auto">
          <Col className="d-flex align-items-center mb-3">
            <img
              src="../images/blogGamze.png"
              alt="img-hero"
              className="img-fluid rounded-circle"
              width={"35px"}
            />
            <span>&nbsp;Gamze Nur Akdemir</span>
          </Col>
          <img
            src="../images/profesyonelyasamkocudestegi.png"
            alt="img-hero"
            className="img-fluid mx-auto mb-3"
          />

          <p>
            Eğitim ve Öğrenci Koçu kişilerin hedef belirleme ve hedefe ulaşma
            sürecinde kişinin çalışma içerisinde doğru yöntem belirler,
            destekler motive eder ve başarı elde etme becerilerinin
            artırılmasına yönelik çalışmalar yürütür. Kişinin, kendi potansiyel
            ve yeteneklerine yönelik sağlıklı hedefler belirler ve hangi
            kaynaklara ihtiyacı olduğu analiz eder, sahip oldukları kaynakları
            ise daha iyi kullanabilmesi için kişiye yönelik yol haritası
            belirler. Bireyin kendi potansiyelini keşfetme, hedeflerini
            belirlemede çok önemli rol oynar. Eğitim koçunun genel görevleri:
          </p>
          <ul>
            <li>
              Kişinin kendini tanıması ve hedeflerini belirlemesini sağlamak ve
              motive etmek,
            </li>
            <li>
              Belirlenen hedefe ulaşmada eylem planları ve stratejiler
              belirlemek,
            </li>
            <li>
              Kişiye özel plan ve program geliştirerek, plandaki devamlılığı ve
              çalışmaları duzenli denetlemek,
            </li>
            <li>
              Kişinin aile ve sosyal çevresi ile iletişimindeki aksaklıkları
              keşfederek gidermesi için yol göstermek,
            </li>
            <li>
              Bireyin ilgi ve yeteneklerini tespit ederek mesleki yönlendirme
              yapmak,
            </li>
            <li>
              Okul ve sınavlarda bireysel takip sağlamak ve başarının
              arttırılmasına yönelik çözümler geliştirmek,
            </li>
            <li>
              Kaygı ve strese neden olan durumları ortadan kaldırmaya çalışmak,
            </li>
            <li>
              Öz disiplin, özgüven ve sorumluluk biliinci kazanmasına yardımcı
              olmak,
            </li>
            <li>Farkındalık geliştirme ortamları yaratır</li>
            <li>
              Eğitim teknolojilerinin etkili kullanımına yönelik
              bilgilendirmeler yapmak,
            </li>
            <li>Kişinin sosyal gelişimine katkıda bulunmak</li>
          </ul>
          <p>Bir Eğitim Koçunda Olması Gereken Genel Özellikler:</p>
          <ol>
            <li>Çözüm üretme yeteneği</li>
            <li>Olumlu düşünce ve yüksek motivasyon </li>
            <li>Planlama, takip becerisi </li>
            <li>Sabırlı,pozitif ve hoşgörülü olmak,</li>
            <li>Etkili iletişim becerisi</li>
            <li>Analitik bakış açısına sahip olmak,</li>
            <li>Empati yapabilmek </li>
            <li>
              Gerek akademik gerek profesyonel yaşamında başarılar elde etmiş ve
              bu yolda deneyime sahip olmalı
            </li>
            <li>İhtiyacı keşfetme ve analiz yeteneği kuvvetli olmalı </li>
            <li>Startejik yaklaşımı ve kriz yönetebilme becerisi olmalıdır.</li>
          </ol>
        </Row>
      </Container>
    </section>
  );
}
